import React from "react"

export default ({
    title, 
    image, 
    features
}) => {
    const firstFourFeatures = features.slice(0,4)
    const lastFourFeatures = features.slice(4,8)

    return (
        <div className="technology-features">
            <div className="container">
                <h2 dangerouslySetInnerHTML={{ __html: title }} />
                <div className="technology-features-cont">
                    <ul>
                        {firstFourFeatures.map((row, index) => (
                            <li key={index}>
                                <div>
                                    { row.feature }
                                </div>
                            </li>
                        ))}
                    </ul>
                    <div className="technology-features-image">
                        <img src={image.mediaItemUrl } alt={title } />
                    </div>
                    <ul>
                        {lastFourFeatures.map((row, index) => (
                            <li key={index}>
                                <div>
                                    { row.feature }
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}
