import React from 'react'
import LogosSpot from './logosSpot'

export default ({
    title, 
    logos,
    groupLogosBy
}) => {
    const itemsPerColumn = groupLogosBy ? Math.ceil(logos.length/groupLogosBy) : 1

    const spots = [[]]

    logos.forEach(logo => {
        const lastSpot = spots[spots.length - 1]

        if(lastSpot.length < itemsPerColumn){
            lastSpot.push(logo.logo.mediaItemUrl)
        }else{
            spots.push([logo.logo.mediaItemUrl])
        }
    })

    return (
        <div className="logos">
            <div className="container">
                <div className="wrapper">
                    <h3>
                        {title}
                    </h3>
                    <ul>
                        {spots.map((_logos, index) => {
                            return (
                                <LogosSpot 
                                    key={index} 
                                    index={index}
                                    logos={_logos} 
                                />
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
    )
}