import React from 'react'

export default ({heading, copy, image}) => {
    const backgroundUrl = image && image.mediaItemUrl
    return (
        <div className="image-and-copy">
            <div className="container">
                <div className="text">
                    <h2>
                        {heading}
                    </h2>
                    <div className="copy" dangerouslySetInnerHTML={{ __html: copy }}></div>
                </div>
                <div className="image" style={{backgroundImage: "url(" + backgroundUrl + ")"}}></div>
            </div>
        </div>
    )
}