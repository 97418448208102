import React from 'react'
import Button from 'components/button'

export default ({title, copy, button, pretitle}) => {
    return (
        <div className="big-paragraph">
            <div className="big-paragraph-text">
                <h2 
                    className="h1" 
                    dangerouslySetInnerHTML={{ __html: title }} 
                />
                <div className="big-paragraph-content">
                    <div 
                        className="big-paragraph-copy" 
                        dangerouslySetInnerHTML={{ __html: copy }}
                    />
                    { button &&
                        <Button 
                            url={button['url'] } 
                            label={button['title'] } 
                        />
                    }
                </div>
                <div className="tri">
                    <div></div>
                    <h4>
                        { pretitle }
                    </h4>
                </div>
            </div>
        </div>
    )
}
