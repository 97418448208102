import React, { useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Button from 'components/button'
import BlogGridPost from "components/blogGridPost"
import Flickity from 'react-flickity-component'
import 'flickity/css/flickity.css'

export default ({ title, copy }) => {

	const data = useStaticQuery(graphql`
    query BlogQuery {
      siteFields {
        posts {
          edges {
            node {
              link
              title
              excerpt
              date
              featuredImage {
                mediaItemUrl
              }
              categories {
                nodes {
                  name
                  slug
                }
              }
              tags {
                nodes {
                  name
                  slug
                }
              }
            }
          }
        }
      }
    }
  `)

	const sliderRef = useRef(null)

	const posts = data.siteFields.posts.edges

	const firstTwoPosts = posts.slice(0, 4)

	let isMobile = false

	if (typeof window != 'undefined') {
		if (window.outerWidth <= 500) {
			isMobile = true
		}
	}

	let pageDots = false

	if (isMobile) {
		pageDots = true
	}

	const flickityOptions = {
		wrapAround: true,
		prevNextButtons: false,
		pageDots,
		groupCells: isMobile ? 1 : 2
	}

	useEffect(() => {
		setTimeout(() => {
			sliderRef.current.resize()
		}, 600)
	}, [])

	return (
		<div className="blog-grid blog-grid-home">
			<div className="container">
				<div className="blog-grid-heading">
					<h2 className="h1" dangerouslySetInnerHTML={{ __html: title }} />
					<p dangerouslySetInnerHTML={{ __html: copy }} />
					<Button url="/news/" label="View all" />
				</div>
				<Flickity
					className={'carousel'}
					options={flickityOptions}
					reloadOnUpdate
					static
					flickityRef={ref => sliderRef.current = ref}
				>
					{ firstTwoPosts.map((row, index) => (
						<BlogGridPost post={row.node} key={index} />
					))}
				</Flickity>
			</div>
		</div>
	)
}
