import React from 'react'

export default ({
    image,
    subtitle,
    title,
    values,
    graphCopy,
    isVisible,
}) => {
    const highestValue = values ? values.map((value) => value.percentage).sort((a, b) => b - a)[0] : 100

    return (
        <div className="graph">
            <div className="container">
                <div className="graph-intro">
                    { image &&
                        <div
                            className="graph-image"
                            style={{ backgroundImage: `url(${image.mediaItemUrl})` }}
                        />
                    }
                    <div className="graph-text">
                        { subtitle &&
                            <h5>{subtitle}</h5>
                        }
                        { title &&
                            <h3>{title}</h3>
                        }
                    </div>
                </div>
                <div className="graph-main">
                    { values &&
                        <ul>
                            {values.map((value, index) => {
                                const {
                                    title,
                                    percentage,
                                } = value

                                const _percentage = percentage * 100 / highestValue

                                return (
                                    <li
                                        key={index}
                                        data-percentage={`${percentage}%`}
                                        style={{ height: `${isVisible ? _percentage : 0}%` }}
                                    >
                                        <h4>{title}</h4>
                                    </li>
                                )
                            })}
                        </ul>
                    }
                    { graphCopy &&
                        <div className="graph-copy">
                            {graphCopy}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
