import React, { useState } from "react"

import { nl2br } from 'utils'

export default ({
    heading, 
    type, 
    backgroundImage, 
    gridItems
}) => {
    const [selectedPoint, setSelectedPoint] = useState(-1)

    const backgroundUrl = backgroundImage ? backgroundImage.mediaItemUrl : ''

    return (
        <div className={`bullet-grid ${type} ${ backgroundUrl ? 'hasBG' : ''}`} style={{backgroundImage: `url'(${ backgroundUrl }')`}}>
            <div className="container">
                <h2 dangerouslySetInnerHTML={{ __html: heading }} />
                <ul>
                    { gridItems.map((gridItem, index) => {
                        const {
                            icon,
                            heading: _heading,
                            copy
                        } = gridItem

                        const iconClass = icon ? 'bullet-grid-icon' : ''

                        if(!_heading && !copy){
                            return null
                        }

                        return (
                            <li 
                                key={index} 
                                className={index === selectedPoint ? 'active' : '' } 
                                onClick={() => setSelectedPoint(index)}
                                style={{
                                    transitionDelay: (index/10)+'s'
                                }}
                            >
                                { icon ? 
                                    <div className={iconClass } style={{ backgroundImage: `url(${icon.mediaItemUrl})` }} />
                                : null }
                                <div className="bullet-grid-text">
                                    <h4 dangerouslySetInnerHTML={{ __html: _heading }} />
                                    <p dangerouslySetInnerHTML={{ __html: nl2br(copy) }} />
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}
