import React, {useState, useRef} from 'react'
import Link from 'components/link'
import Button from './button'

import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

export default ({
    hideComponent,
    title, 
    copy, 
    copyAlignment, 
    button, 
    buttonAlignment, 
    services = [], 
    videoPlaceholder, 
    video
}) => {
    const [videoPlaying, setVideoPlaying] = useState(0)

    const _services = services || []

    const buttonUrl = button && button.url 
    const buttonTitle = button && button.title

    let videoContainerClass = "video-container"

    if(videoPlaying) {
        videoContainerClass += " is-playing"
    }

    const vidRef = useRef(null)
    
    const playVideo = _ => {

        if(videoPlaying === 0) {
            setVideoPlaying(1)
            vidRef.current.play()

            trackCustomEvent({
                category: "Videos",
                action: "Play",
                label: 'Our Services'
            })
        } else {
            vidRef.current.pause()
            setVideoPlaying(0)
        }
    }

    const itemsPerRow = Math.ceil(_services.length/2)

    const columns = [_services.slice(0, itemsPerRow), _services.slice(itemsPerRow, _services.length)]

    if(hideComponent){
        return null
    }

    return (
        <div className="text-and-video">
            <div className="heading-row">
                <div className="container">
                    <h2 className="h1">{ title }</h2>
                </div>
            </div>
            <div className="bottom-row">
                <div className="container">
                    <div className="left-column">
                        { services &&
                            <ul>
                                { columns.map((column, index) => {
                                    return (
                                        <li key={index}>
                                            <ul>
                                                { column.map(({ service }, rowIndex) => {
                                                    const {
                                                        url,
                                                        title,
                                                        link
                                                    } = service

                                                    return (
                                                        <li key={rowIndex}>
                                                            <Link 
                                                                to={url || link || '/' }
                                                                dangerouslySetInnerHTML={{ __html: title }}
                                                                className="copy"
                                                            />
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </li>
                                    )
                                })}
                            </ul>
                        }
                        {  copyAlignment === 'left' ?
                            <p>
                                {copy}
                            </p>
                        :  null }
                        { buttonAlignment === 'left' ?
                            <Button url={buttonUrl } label={buttonTitle } />  
                        : null }
                    </div>
                    <div className="image-text-column">
                        <div className={videoContainerClass}>
                            {videoPlaceholder &&
                                <div className="placeholder" onClick={() => {playVideo()}}>
                                    <img 
                                        alt=""
                                        src={videoPlaceholder.mediaItemUrl}
                                    />
                                </div>
                            }
                            {video &&
                                <div className="video" onClick={() => {playVideo(0)}}>
                                    <video ref={vidRef} controls>
                                        <source 
                                            src={video.mediaItemUrl} 
                                            type="video/mp4" 
                                        />
                                    </video>
                                </div>
                            }
                        </div>
                        { copyAlignment === 'right' ?
                            <p>
                                 { copy }
                            </p>
                        : null }
                        { buttonAlignment === 'right' ?
                            <Button url="/services" />
                        : null }
                    </div>
                </div>
            </div>
        </div>
    )
}
