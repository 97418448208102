import React, { useEffect, useState, useRef } from 'react'

export default ({
    logos,
    index
}) => {
    const [visibleLogoIndex, setVisibleLogoIndex] = useState(0)
    const visibleLogoIndexRef = useRef(visibleLogoIndex)

    visibleLogoIndexRef.current = visibleLogoIndex

    useEffect(() => {
        const timer = setInterval(() => {
            const visibleIndex = visibleLogoIndexRef.current

            const newIndex = logos[(visibleIndex + 1)] ? visibleIndex + 1 : 0
            setVisibleLogoIndex(newIndex)
        }, (2000 + (index * 20)))

        return () => {
            clearInterval(timer)
        }
    }, [])

    return (
        <li 
            key={index}
            style={{
                transitionDelay: (index/10)+'s'
            }}
        >
            { logos.map((logo, logoIndex) => {
                return (
                    <img 
                        className={visibleLogoIndex === logoIndex ? 'visible' : ''}
                        key={logoIndex} 
                        src={logo} 
                    />
                )
            })}
        </li>
    )
}