import React from 'react'
import Button from 'components/button'

export default ({heading, copy, backgroundImage, button}) => {

    const backgroundUrl = backgroundImage && backgroundImage.mediaItemUrl

    const buttonURL = button.url 

    const buttonTitle = button.title 

    return (
        <div className="cta">
            <div className="container">
                <div className="background" style={{backgroundImage: "url(" + backgroundUrl + ")"}}>
                    <div className="column">
                        <h2>
                            {heading}
                        </h2>
                        <p>
                            {copy}
                        </p>
                    <Button url={buttonURL } label={buttonTitle } type="button" />
                    </div>
                    <div className="column">

                    </div>
                </div>
            </div>
        </div>
    )
}
