import React, {useRef} from 'react'
import Flickity from 'react-flickity-component'
import { useStaticQuery, graphql } from "gatsby"
import 'vendor/flickity-as-nav-for/as-nav-for'
import 'flickity/css/flickity.css'

export default ({heading, copy}) => {
    const peopleQuery = useStaticQuery(graphql`
        query {
            siteFields {
                people (
                    first: 999
                ) {
                    edges {
                        node {
                            title 
                            featuredImage {
                                mediaItemUrl
                            }
                            peopleFields {
                                position
                                nickname
                            }
                        }
                    }
                }
            }
        }
    `)

    const sliderRef = useRef(null)

    const peopleArray = peopleQuery.siteFields.people.edges

    let isMobile = false 

    if (typeof window != 'undefined') {
        if(window.outerWidth <= 500) {
            isMobile = true
        } 
    }

    const groupCells = isMobile ? 2 : 4
    
    const buttonHandler = command => {
        if(command === 'next') {
            sliderRef.current.next()
        } else {
            sliderRef.current.previous()
        }
    } 

    return (
        <div className="team-slider">
            <div className="container">
                <div>
                    <h2>
                        {heading}
                    </h2>
                    <p dangerouslySetInnerHTML={{ __html: copy }}/>
                    <Flickity
                        className={'carousel'}
                        options={{
                            wrapAround: true,
                            groupCells: groupCells,
                            prevNextButtons: false,
                            pageDots: isMobile,
                        }}
                        flickityRef={ref => sliderRef.current = ref}
                    >
                        {peopleArray.map(({ node: person }, index) => {
                            const {
                                featuredImage,
                                title,
                                peopleFields
                            } = person

                            if(!featuredImage || !peopleFields.position){
                                return null
                            }

                            const { 
                                position,
                                nickname
                            } = peopleFields
                            
                            const backgroundUrl = featuredImage.mediaItemUrl

                            return (
                                <div className="slide" key={index}>
                                    <div className="image" style={{backgroundImage: "url(" + backgroundUrl + ")"}}></div>
                                    <h3 dangerouslySetInnerHTML={{ __html: title }} />
                                    <p>
                                        { position }
                                    </p>
                                    <span className="sub">
                                        { nickname }
                                    </span>
                                </div>   
                            )
                        })}
                    </Flickity>
                    <div className="navigation">
                        <div className="buttons">
                            { ['back','next'].map(direction => (
                                <div 
                                    key={direction}
                                    className={`arrow ${ direction }`} 
                                    onClick={() => {buttonHandler(direction)}} 
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}