import React, { useContext, useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { MainContext } from "context/context"
import Link from 'components/link'

import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

export default ({title, videos}) => {
	const {
        openModal,
        addInvertedHeaderSection
    } = useContext(MainContext)

    const elementRef = useRef(null)
    
    useEffect(() => {
        if(elementRef && elementRef.current){
            addInvertedHeaderSection(elementRef.current)
        }
    }, [elementRef])

    const serviceQuery = useStaticQuery(graphql`
        query serviceQuery {
            siteFields {
				services {
					edges {
						node {
							slug
							title
						}
					}
				}    
            }
        }
    `)

    const handleOpenVideo = (url, _title) => {
        trackCustomEvent({
          category: "Videos",
          action: "Play",
          label: _title
        })

        openModal({
            type: 'video', 
            video: url,
            videoTitle: _title 
        })
    }

    const services = serviceQuery.siteFields.services.edges

    return (
        <div className="hero-blue" ref={ref => elementRef.current = ref}>
            <div className="hero-blue-cont">
                <div className="hero-blue-text">
                    <h1 dangerouslySetInnerHTML={{ __html: title }} />
                    <ul className="hero-blue-videos">
                        { videos && videos.map((row, index) => {
                            const featuredImage = row.video.featuredImage
                            const video = row.video.videoFields.video

                            let videoURL = ''

                            if (video) {
                                videoURL = video.mediaItemUrl
                            }
                            
                            return (
                                <li 
                                    key={index} 
                                    data-video={videoURL} 
                                    onClick={_ => handleOpenVideo(videoURL, row.video.title )}
                                >
                                    <div style={{ backgroundImage: `url(${featuredImage.mediaItemUrl})` }} />
                                    { row.video.title }
                                </li>
                            )
                        })}
                    </ul>

                </div>
                <div className="hero-blue-services">
                    <ul>
                        { services.map((row, index) => {
                            return (
                                <li 
                                    key={index}
                                    style={{
                                        transitionDelay: (index/10)+'s'
                                    }}
                                >
                                    <h3>
                                        <Link 
                                            to={'/services/'+row.node.slug } 
                                            dangerouslySetInnerHTML={{ __html: row.node.title }} 
                                        />
                                    </h3>
                                </li>
                            )
                        })}
                    </ul>

                </div>
            </div>
        </div>
    )
}
