import React, { useContext, useRef, useEffect } from "react"
import { MainContext } from "../context/context"

export default ({title, copy, preTitle, logo, sideImage}) => {
    const {
        addInvertedHeaderSection
    } = useContext(MainContext)

    const elementRef = useRef(null)
    
    useEffect(() => {
        if(elementRef && elementRef.current){
            addInvertedHeaderSection(elementRef.current)
        }
    }, [elementRef])

    return (
        <div className="hero-technology" ref={ref => elementRef.current = ref}>
            <div className="hero-technology-cont">
                <div className="hero-technology-text">

                    <h4 dangerouslySetInnerHTML={{ __html: preTitle }} />

                    <h1 dangerouslySetInnerHTML={{ __html: title }} />

                    <div dangerouslySetInnerHTML={{ __html: copy }} />

                    <img src={logo.mediaItemUrl } alt={title } />

                </div>
                <div className="hero-technology-image">

                    <img src={sideImage.mediaItemUrl } alt={title } />

                </div>
            </div>
        </div>
    )
}
