import React from "react"
import { graphql } from "gatsby"


import SEO from "components/seo"
import FlexibleContent from "components/flexibleContent"

export default ({ data }) => {
	const { 
		databaseId, 
		title,
    flexibleContent,
    content,
		seoFields
  } = data.siteFields.page  
  
  let excerpt = ''

  if (content) {
    excerpt = content.replace(/(<([^>]+)>)/ig,"")
    excerpt = excerpt.slice(0,160)
  }

	const _title = (seoFields && seoFields.metaTitle) ? seoFields.metaTitle : title
	const _description = (seoFields && seoFields.metaDescription) ? seoFields.metaDescription : excerpt
	const _image = (seoFields && seoFields.ogpImage) ? seoFields.ogpImage.mediaItemUrl : null

	return (
		<>
      <SEO 
        title={_title} 
        description={_description} 
        image={_image}
      />
			<FlexibleContent id={databaseId} content={flexibleContent} />
		</>
	)
}

export const query = graphql`
  query($databaseId: ID!) {
    siteFields {
      page(id: $databaseId, idType: DATABASE_ID) {
        databaseId
        title
        date
        content(format: RENDERED)
				seoFields {
					metaTitle
					metaDescription
					ogpImage {
						mediaItemUrl
					}
				}
        featuredImage {
          altText
          title(format: RENDERED)
          mediaItemUrl
          slug
        }
        flexibleContent {
          flexibleContent {
            __typename
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_Hero {
              button {
                target 
                title 
                url
              }
              copy 
              title
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_HeroBlue {
              title
              videos {
                video {
                  ... on WPGraphQL_Video {
                    title
                    featuredImage {
                      altText
                      title(format: RENDERED)
                      mediaItemUrl
                      slug
                    }
                    videoFields {
                      video {
                        mediaItemUrl
                      }
                    }
                  }
                }
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_HeroTechnology {
              title
              preTitle
              copy
              logo {
                mediaItemUrl
              }
              sideImage {
                mediaItemUrl
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_Banner {
              title
              copy
              theme
              image {
                mediaItemUrl
              }
              button {
                title
                url
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_TechnologyFeatures {
              title
              image {
                mediaItemUrl
              }
              features {
                feature
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_Logos {
              title 
              groupLogosBy
              logos {
                logo {
                  mediaItemUrl
                }
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_BulletGrid {
              heading
              backgroundImage {
                mediaItemUrl
              }
              type
              gridItems {
                icon {
                  mediaItemUrl
                }
                heading
                copy
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_TeamSlider {
              heading 
              copy
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_Cta {
              heading 
              copy
              backgroundImage {
                mediaItemUrl
              }
              button {
                url
                title
                target
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_ImageAndCopy {
              heading 
              copy
              image {
                mediaItemUrl
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_BigParagraph {
              pretitle
              title 
              copy
              button {
                target 
                title 
                url
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_Country {
              heading
              copy
              states {
                image {
                  mediaItemUrl
                }
                stat
                state
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_BlogHome {
              title 
              copy
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_TextAndVideo {
              hideComponent
              title 
              copy
              copyAlignment
              button {
                target
                title
                url
              }
              buttonAlignment
              videoPlaceholder {
                mediaItemUrl
              }
              video {
                mediaItemUrl
              }
              services {
                service {
                  ... on WPGraphQL_Service {
                    title 
                    link
                    id
                  }
                }
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_HighlightCarousel {
              highlightCarousel {
                service {
                  ... on WPGraphQL_Service {
                    title
                    slug
                  }
                }
                highlightTitle
                highlightFigure
                highlightDescription
                quote
                logos {
                  logo {
                    mediaItemUrl
                  }
                }
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_AustralianMade {
              heading
              copy
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_Graph {
              image {
                mediaItemUrl
              }
              subtitle
              title
              values {
                title
                percentage
              }
              graphCopy
            }
          }
        }
      }
    }
  }
`
