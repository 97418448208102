import React from 'react'
import AustralianMap from '../images/australian-map.png'
import Triangles from '../images/triangles.png'

export default ({heading, copy}) => {
    return (
        <div className="australian-made">
            <div className="container">
                <div className="content" style={{ backgroundImage: `url(${Triangles})` }}>
                    <img className="australian-map" src={AustralianMap}/>
                    <div className="text-column">
                        <h3>
                            {heading}
                        </h3>
                        <div 
                            className="copy" 
                            dangerouslySetInnerHTML={{ __html: copy }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
