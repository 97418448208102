import React, { useState } from 'react'
import { times } from 'lodash'

export default ({highlightCarousel}) => {
    const [activeSlide, setActiveSlide] = useState(0)
    const [areSlidesVisible, setAreSlidesVisible] = useState(true)

    const highlightsArray = []

    highlightCarousel.map((slide => {
        highlightsArray.push(slide)
    }))

    const totalSlides = highlightCarousel.length

    const slide = highlightsArray[activeSlide]

    let _logos = []
    const logos = slide.logos
    if (logos) {
        _logos = logos
    }

    const changeSlide = operator => {
        let nextSlide = 0

        if(operator === 'plus') {
            if((activeSlide + 1) === totalSlides ){
                nextSlide = 0
            } else {
                nextSlide = activeSlide + 1
            }
        } else {
            if(!activeSlide) {
                nextSlide = totalSlides - 1
            } else {
                nextSlide = activeSlide - 1
            }
        }

        setAreSlidesVisible(false)

        setTimeout(() => {
            setActiveSlide(nextSlide)
            setAreSlidesVisible(true)
        },350)
    }

    return (
        <div className={`highlight-carousel ${ areSlidesVisible ? 'visible' : '' }`}>
            <div className="container">
                <div className="current-slide">
                    <div className="box">
                        <div>
                            <h4 dangerouslySetInnerHTML={{ __html: slide.highlightTitle }} />
                            <h1 dangerouslySetInnerHTML={{ __html: slide.highlightFigure }} />
                            <h4 dangerouslySetInnerHTML={{ __html: slide.highlightDescription }} />
                        </div>
                    </div>
                    <div className="slider">
                        <div className="heading-row">
                            <h4 dangerouslySetInnerHTML={{ __html: slide.service.title }} />
                            <div className="navigator">
                                <span className="dots">
                                    { times(totalSlides, index => {
                                        return (
                                            <i key={index} className={activeSlide >= index ? 'active' : ''} />
                                        )
                                    })}
                                </span>
                                <div className="back arrow" onClick={() => changeSlide('minus')}>
                                    <i className="fe fe-arrow-left"></i>
                                </div>
                                <div className="forward arrow" onClick={() => changeSlide('plus')}>
                                    <i className="fe fe-arrow-right"></i>
                                </div>
                            </div>
                        </div>
                        <div className="bottom-row">
                            <p>
                                {slide.quote}
                            </p>
                            <ul className="logos">
                                {_logos.map((row, index) => {
                                    return (
                                        <li key={index}>
                                            <img src={row.logo.mediaItemUrl} />
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
