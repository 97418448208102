import { useEffect, useRef, useState, useContext } from 'react'
import { isEqual } from 'lodash'
import { MainContext } from 'context/context'

import React from 'react'
import Hero from 'components/hero'
import HeroBlue from 'components/heroBlue'
import HeroTechnology from 'components/heroTechnology'
import Logos from 'components/logos'
import BigParagraph from 'components/bigParagraph'
import TextAndVideo from 'components/textAndVideo'
import HighlightCarousel from 'components/highlightCarousel'
import BlogHome from 'components/blogHome'
import BulletGrid from 'components/bulletGrid'
import TeamSlider from 'components/teamSlider'
import Country from 'components/country'
import TechnologyFeatures from 'components/technologyFeatures'
import Banner from 'components/banner'
import ImageAndCopy from 'components/imageAndCopy'
import Cta from 'components/cta'
import ScoutIframe from 'components/scoutIframe'
import AustralianMade from 'components/australianMade'
import Graph from 'components/graph'

const components = {
    Hero,
    HeroBlue,
    HeroTechnology,
    Logos, 
    BigParagraph,
    TextAndVideo,
    HighlightCarousel,
    BulletGrid,
    TeamSlider,
    Country,
    BlogHome,
    TechnologyFeatures,
    Banner,
    ImageAndCopy,
    Cta,
    ScoutIframe,
    AustralianMade,
    Graph,
}

export default ({ content }) => {
    const componentRefs = useRef([])
    const [visibleIndices, setVisibleIndices] = useState([])

    const visibleIndicesRef = useRef(visibleIndices)

    visibleIndicesRef.current = visibleIndices

    const handleScroll = () => {
        let _visibleIndices = []

        componentRefs.current.forEach((componentRef, index) => {
            if(componentRef){
                const offset = componentRef.getBoundingClientRect().top

                let windowHeight = null
                
                if (typeof window != 'undefined') {
                    windowHeight = window.innerHeight
                }

                if(offset < (windowHeight / 3 * 2)) {
                    _visibleIndices.push(index) 
                }
            }
        })

        if(!isEqual(_visibleIndices,visibleIndicesRef.current)) {
            setVisibleIndices(_visibleIndices)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)

        setTimeout(() => {
            handleScroll()
        }, 1000)
        
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const addComponent = (ref, index) => {
        if(ref){
            const _componentRefs =  [...componentRefs.current]

            _componentRefs[index] = ref

            componentRefs.current = _componentRefs
        }
    }

    return (
        <div>
            { content.flexibleContent.map((row, index) => {
                const Component = row.__typename.replace('WPGraphQL_Page_Flexiblecontent_FlexibleContent_','')
                const Tag = components[Component]

                const isVisible = visibleIndices && visibleIndices.indexOf(index) !== -1

                if(row.hideComponent){
                    return null
                }

                return (
                    <section 
                        className={`${ isVisible ? 'scrolled-into-view' : ''} section-parent-${ Component }`} 
                        ref={ref => addComponent(ref, index)} 
                        key={index}
                    >
                        <Tag {...row} isVisible={isVisible} />
                    </section>
                )
            }) }
        </div>
    )
}

