import React from 'react'

export default ({heading, copy, states}) => {

    return (
        <div className="country">
            <div className="container">
                <div className="text">
                    <h2>
                        {heading}
                    </h2>
                    <p>
                        {copy}
                    </p>
                </div>
                <div className="map">
                    <div className="image">
                        {states.map((state, index) => {
                            return (
                                <div className={state.state} key={index}>
                                    <img src={state.image.mediaItemUrl}/>
                                    <div className="info">
                                        <div className="triangle"></div>
                                        <span>
                                            {state.state}
                                        </span>
                                        <h3>
                                            {state.stat}
                                        </h3>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}