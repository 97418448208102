import React from 'react'

export default () => {
    return (
        <>
            <div className="careers-hub old">
                <div className="careers-hub-hero">
                    <div className="container">
                        <h1 className="h2" dangerouslySetInnerHTML={{ __html: 'Get ahead of your career with Strikeforce.' }} />
                    </div>
                </div>
                <div className="container">
                    <div className="iframe-container">
                        <iframe
                            src="https://strikeforce-external.applynow.net.au/"
                            width="100%"
                            style={{
                                height: '2200px',
                                overflow: 'hidden'
                            }}
                            frameborder="0"
                            scrolling="no"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}